@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
iframe.youtube-video {
  width: 100%;
  height: 100%;
}

iframe.youtube-video {
  width: 100%;
  height: 100%;
}



